import React from 'react'
import { Layout } from '../layouts'
import Hero from '../components/Global/Hero'
import { Container } from '../layouts'
import Button from '../components/Global/Button'

const NotFoundPage = () => (
  <Layout>
    <Hero title="404? Well this is awkward..." />
    <Container>
      <p>It's not you, it's us... Something's missing...</p>
      <Button type="link" href="/">
        Try the homepage
      </Button>
    </Container>
  </Layout>
)

export default NotFoundPage
